import React from "react";
import ProgressBar from "./ProgressBar";

interface MultiStepProps {
  totalSteps: number;
  currentStep: number;
}

const MultiStep: React.FC<MultiStepProps> = ({ totalSteps, currentStep }) => {
  const steps = Array.from({ length: totalSteps }, (_, i) => i + 1);

  return (
    <div className="flex flex-col justify-center items-center my-4 w-full" >
      {/* <ProgressBar totalSteps={totalSteps} currentStep={currentStep} /> */}
      <div className="flex justify-center mt-4 items-center gap-8 w-full">
        {steps.map((step, index) => (
          <React.Fragment>
            {/* {index !== 0 && <div className="h-px bg-gray-300 my-auto" />} */}
            <span
              key={step}
              className={` my-4 px-3 py-1 rounded-full ${
                step === currentStep
                  ? "bg-[#038aae] text-white"
                  : "bg-gray-200 text-gray-600"
              }`}
            >
              {step}
            </span>
            {index !== steps.length - 1 && <div className={`h-px bg-gray-300 w-10`} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default MultiStep;
