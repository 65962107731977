import axios from 'axios';
import { handleApiError } from '../api';

interface Region {
  countryID: number;
  name: string;
}

const getRegionsByCountryId = async (countryId: number): Promise<Region[]> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}GetRegionsByCountryId/${countryId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw new Error('Failed to fetch regions');
  }
};

export default getRegionsByCountryId;
