import React, { useEffect, useState } from "react";
import Input from "../components/Input";
import Selection from "../components/Selection";
import getCountries from "../api/general/countriesApi";
import getRegionsByCountryId from "../api/general/regionsApi";
import getCitiesByRegionId from "../api/general/citiesApi";
import { Link } from "react-router-dom";
import { useLogin } from "../hooks/useLogin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import Modal from "../components/SuccessModal";
import { handleApiError } from "../api/api";
const companion1 = require("../images/companion1.png");
const Explore1 = require("../images/explore1.jpg");

interface Country {
  countryID: number;
  name: string;
}

interface Region {
  countryID: number;
  name: string;
}

interface City {
  cityID: number;
  cityName: string;
}

const BecomeCompanion = () => {
  const {
    first_name,
    middle_name,
    middleNameError,
    firstNameError,
    last_name,
    lastNameError,
    phoneNumber,
    phoneNumberError,
    postalCode,
    postalCodeError,
    email,
    emailError,
    address,
    addressError,
    password_confirmation,
    showConfirmPassword,
    password,
    confirmPasswordError,
    passwordError,
    showPassword,
    setIsPasswordFocused,
    isEmailFocused,
    setIsEmailFocused,
    //
    gender,
    setGender,
    //
    errorMessage,
    loading,
    showModal,
    setShowModal,
    //
    countries,
    setCountries,
    cities,
    setCities,
    selectedCityId,
    setSelectedCityId,
    selectedCountryId,
    setSelectedCountryId,
    regions,
    setRegions,
    selectedRegionId,
    setSelectedRegionId,
    //
    genderOptions,
    countryOptions,
    //
    handleFirstNameChange,
    handleMiddleNameChange,
    handleMiddleNameBlur,
    handleFirstNameBlur,
    handleLastNameChange,
    handleLastNameBlur,
    handleCountryCodeChange,
    handlePhoneNumberChange,
    handlePhoneNumberBlur,
    handleAddressChange,
    handleAddressBlur,
    handlePostalCodeChange,
    handlePostalCodeBlur,
    handleSubmit,
    handleEmailChange,
    handleEmailBlur,
    handlePasswordChange,
    handlePasswordBlur,
    handleConfirmPasswordChange,
    handleConfirmPasswordBlur,
    handleShowConfirmPasswordClick,

    handleShowPasswordClick,
  } = useLogin();

  const [formValid, setFormValid] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  useEffect(() => {
    // Check if all required fields are filled
    if (
      first_name &&
      last_name &&
      phoneNumber &&
      email &&
      address &&
      postalCode &&
      password &&
      password_confirmation &&
      selectedCountryId &&
      selectedRegionId &&
      selectedCityId &&
      agreeToTerms
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [
    first_name,
    last_name,
    phoneNumber,
    email,
    address,
    postalCode,
    password,
    password_confirmation,
    selectedCountryId,
    selectedRegionId,
    selectedCityId,
    agreeToTerms,
  ]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesData = await getCountries();
        console.log({ countriesData });
        setCountries(countriesData);
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchRegions = async () => {
      if (selectedCountryId) {
        try {
          const regionsData = await getRegionsByCountryId(selectedCountryId);
          setRegions(regionsData);
        } catch (error) {
          handleApiError(error);
          console.error("Error fetching regions:", error);
        }
      }
    };

    fetchRegions();
  }, [selectedCountryId]);

  useEffect(() => {
    const fetchCities = async () => {
      if (selectedRegionId) {
        try {
          const citiesData = await getCitiesByRegionId(selectedRegionId);
          setCities(citiesData);
        } catch (error) {
          handleApiError(error);
          console.error("Error fetching regions:", error);
        }
      }
    };

    fetchCities();
  }, [selectedRegionId]);

  const handleCountryChange = (value: string) => {
    const countryId = parseInt(value);
    setSelectedCountryId(countryId);
  };

  const handleRegionChange = (value: string) => {
    const regionId = parseInt(value);
    setSelectedRegionId(regionId);
  };

  const handleCityChange = (value: string) => {
    const cityId = parseInt(value);
    setSelectedCityId(cityId);
  };

  const hanldleGenderChange = (value: string) => {
    setGender(value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleAgreeToTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreeToTerms(e.target.checked);
  };

  return (
    <div className="py-8 mt-[55px]">
      {showModal && (
        <Modal closeModal={closeModal} link="/verify-account">
          <p>
            A code has been sent to your email address, please proceed and enter
            the code sent to you to validate your email.
          </p>
        </Modal>
      )}
      {/* <div
        className="h-[400px] w-full relative overflow-hidden flex items-center justify-center z-[9]"
        style={{
          backgroundImage: `url(${companion1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div> */}
      <div className="flex item-start justify-center flex-col mt-8 mb-14">
        <div className="font-bold text-4xl py-3 mx-auto">
          Become A Companion
        </div>
        <div className="bg-[#038aae] h-1 w-72 mx-auto relative left-[-45px]"></div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2 md:py-12 max-w-7xl px-4 md:px-0 mx-auto ">
        <div
          className="rounded-lg h-full w-[500px] relative overflow-hidden flex items-center justify-center z-[9]"
          style={{
            backgroundImage: `url(${Explore1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div className="w-full bg-white form px-4 sm:px-12 py-4">
          <div>
            <h2 className="text-2xl font-bold text-center py-4">
              Fill in Your Details
            </h2>
            <div className="">
              <div className="flex-col md:flex-row flex gap-0 md:gap-4">
                <div className="flex flex-col w-full">
                  <Input
                    label="First Name"
                    type="text"
                    value={first_name}
                    placeholder="first Name"
                    onBlur={handleFirstNameBlur}
                    onChange={handleFirstNameChange}
                    pattern="^[a-zA-Z]*$"
                  />
                  {firstNameError && (
                    <span className="text-red-500 text-sm">
                      {firstNameError}
                    </span>
                  )}
                </div>

                <div className="flex flex-col w-full">
                  <Input
                    label="Middle Name"
                    type="text"
                    value={middle_name}
                    placeholder="Middle Name"
                    onBlur={handleMiddleNameBlur}
                    onChange={handleMiddleNameChange}
                  />
                  {middleNameError && (
                    <span className="text-red-500 text-sm">
                      {middleNameError}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex-col md:flex-row flex gap-0 md:gap-4">
                <div className="flex flex-col w-full">
                  <Input
                    label="Last Name"
                    value={last_name}
                    onChange={handleLastNameChange}
                    onBlur={handleLastNameBlur}
                    type="text"
                    placeholder="Last Name"
                  />
                  {lastNameError && (
                    <span className="text-red-500 text-sm">
                      {lastNameError}
                    </span>
                  )}
                </div>
                <Selection
                  label="Gender"
                  options={genderOptions}
                  onChange={hanldleGenderChange}
                />
              </div>

              <div className="flex-col md:flex-row flex gap-0 md:gap-4">
                <Selection
                  label="Country Code"
                  options={countryOptions}
                  onChange={handleCountryCodeChange}
                />
                <div className="flex flex-col w-full">
                  <Input
                    label="Phone Number"
                    type="tel"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    onBlur={handlePhoneNumberBlur}
                    placeholder="Phone Number"
                  />
                  {phoneNumberError && (
                    <span className="text-red-500 text-sm">
                      {phoneNumberError}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex-col md:flex-row flex gap-0 md:gap-4">
                <div className="flex flex-col w-full">
                  <Input
                    label="Email"
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                    type="email"
                    placeholder="Email Address"
                  />
                  {emailError && (
                    <span className="text-red-500 text-sm">{emailError}</span>
                  )}
                </div>
                <Selection
                  label="Country"
                  options={countries.map((country) => ({
                    value: country.countryID.toString(),
                    label: country.name,
                  }))}
                  onChange={handleCountryChange}
                />
              </div>
              <div className="flex-col md:flex-row flex gap-0 md:gap-4">
                <Selection
                  label="Region"
                  options={regions.map((region) => ({
                    value: region.countryID.toString(),
                    label: region.name,
                  }))}
                  onChange={handleRegionChange}
                />
                <Selection
                  label="City"
                  options={cities.map((city) => ({
                    value: city.cityID.toString(),
                    label: city.cityName,
                  }))}
                  onChange={handleCityChange}
                />
              </div>
              <div className="flex-col md:flex-row flex gap-0 md:gap-4">
                <div className="flex flex-col w-full">
                  <Input
                    label="Residential Address"
                    type="text"
                    value={address}
                    onBlur={handleAddressBlur}
                    onChange={handleAddressChange}
                    placeholder="Residential Address"
                  />
                  {addressError && (
                    <span className="text-red-500 text-sm">{addressError}</span>
                  )}
                </div>
                <div className="flex flex-col w-full">
                  <Input
                    label="Postal Code"
                    type="text"
                    value={postalCode}
                    onBlur={handlePostalCodeBlur}
                    onChange={handlePostalCodeChange}
                    placeholder="Postal Code"
                  />
                  {postalCodeError && (
                    <span className="text-red-500 text-sm">
                      {postalCodeError}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="relative">
                  <Input
                    label="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    onBlur={handlePasswordBlur}
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                  />
                  <div
                    className="absolute bottom-[-6px] right-1 transform -translate-y-1/2 cursor-pointer"
                    onClick={handleShowPasswordClick}
                  >
                    <div className={`w-8 h-8 bg-white text-[#038aae]}`}>
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </div>
                  </div>
                </div>
                {passwordError && (
                  <span className="text-red-500 text-sm">{passwordError}</span>
                )}
              </div>
              <div className="flex flex-col w-full">
                <div className="relative">
                  <Input
                    label="Confirm Password"
                    value={password_confirmation}
                    onChange={handleConfirmPasswordChange}
                    onBlur={handleConfirmPasswordBlur}
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                  />
                  <div
                    className="absolute bottom-[-6px] right-1 transform -translate-y-1/2 cursor-pointer"
                    onClick={handleShowConfirmPasswordClick}
                  >
                    <div className={`w-8 h-8 bg-white text-[#038aae]}`}>
                      {showConfirmPassword ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </div>
                  </div>
                </div>
                {confirmPasswordError && (
                  <span className="text-red-500 text-sm">
                    {confirmPasswordError}
                  </span>
                )}
              </div>

              <div className="flex items-center justify-start gap-2 my-2">
                <input
                  type="checkbox"
                  name="agree"
                  id="agreeToTerms"
                  checked={agreeToTerms}
                  onChange={handleAgreeToTermsChange}
                />
                <p className="text-sm">
                  I agree to the{" "}
                  <Link
                    to="/terms-and-conditions"
                    target="_blanck"
                    className="text-[#038aae]"
                  >
                    terms and conditions
                  </Link>
                </p>
              </div>
              <button
                className={`my-2 bg-gradient-to-br from-[#038aae] to-[#038aae90] text-white py-2 px-6 rounded-lg ${
                  formValid || loading ? "" : "cursor-not-allowed"
                }`}
                onClick={handleSubmit}
                disabled={!formValid || loading}
              >
                {loading ? "processing" : "proceed"}
              </button>

              <p className="text-lg text-red-500">{errorMessage}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomeCompanion;
