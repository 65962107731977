import React, { useState } from "react";

interface MultiSelectionProps {
  label: string;
  options: { value: string; label: string }[];
  onChange?: (value: string) => void;
}

const MultiSelection: React.FC<MultiSelectionProps> = ({
  label,
  options,
  onChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>("");

  console.log({ selectedOptions });

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
    if (!selectedOptions.includes(value)) {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const handleCancel = (value: string) => {
    const updatedOptions = selectedOptions.filter((option) => option !== value);
    setSelectedOptions(updatedOptions);
  };

  return (
    <div className="py-2 flex flex-col gap-1 w-full">
      <label className="select-label text-gray-500">{label}</label>
      <select
        className="select rounded-md border border-gray-400 p-2 w-full"
        value={selectedValue}
        onChange={handleChange}
      >
        <option value="" disabled>
          Choose your {label}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.label}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="max-w-6xl mx-auto overflow-auto"></div>
      {selectedOptions.length > 0 && (
        <div className="selected-options flex gap-3 w-full overflow-x-auto whitespace-nowrap">
          {selectedOptions.map((option) => (
            <div
              key={option}
              className="text-sm selected-option flex gap-2 bg-gray-200 py-1 px-1 rounded-lg my-2"
            >
              {option}
              <button
                className="cancel-btn text-white px-2 bg-red-500 rounded-full text-center"
                onClick={() => handleCancel(option)}
              >
                x
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelection;
// https://apistaging.gtcare.ca/api/TCare/AddCompanionService
