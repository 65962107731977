import axios from 'axios';
import { handleApiError } from '../api';

const getCountries = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}GetCountry`);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw new Error('Failed to fetch countries');
  }
};

export default getCountries;
