// Routes.tsx

import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home";
import FindCompanion from "./pages/FindCompanion";
import BecomeCompanion from "./pages/BecomeCompanion";
import VerifyAccount from "./pages/VerifyAccount";
import MultiStepRegistration from "./components/MultiStepReg";
import FindCompanionSearch from "./pages/FindCompanionSearch";
import TermsAndConditions from "./pages/TermsAndConditions";
import Login from "./pages/Login";
import SeniorPortal from "./pages/SeniorPortal";
import RegistrationCompleted from "./pages/RegistrationCompleted";
import ExploreCompanion from "./pages/ExploreCompanion";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
// import About from "./pages/About";

const Pages: React.FC = () => {
  return (
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/find-companion" element={<FindCompanion />} />
          <Route path="/become-companion" element={<BecomeCompanion />} />
          <Route path="/verify-account" element={<VerifyAccount />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/companion-step-1" element={<CompanionStepOne />} /> */}
          {/* <Route path="/companion-step-1" element={<CompanionStepOne />} /> */}
          <Route path="/companion-registration" element={<MultiStepRegistration />} />
          <Route path="/find-companions" element={<FindCompanionSearch />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/senior-portal" element={<SeniorPortal />} />
          <Route path="/registration-completed" element={<RegistrationCompleted />} />
          <Route path="/explore-companion/:companionId" element={<ExploreCompanion />} />
        </Routes>
      </Layout>
  );
};

export default Pages;
