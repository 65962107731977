import axios from 'axios';
import { handleApiError } from '../api';

interface Facility {
    residentID: number;
    residentName: string;
  }

export const getFacilitiesByCityId = async (cityId: number): Promise<Facility[]> => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}GetResidentByCityID/${cityId}`;
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw new Error('Failed to fetch regions');
  }
};

export const getFacilities = async () => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}GetResidents`;
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw new Error('Failed to fetch regions');
  }
};

// export default getFacilitiesByCityId;