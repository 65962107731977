import React from "react";

interface SelectionProps {
  label: string;
  options: { value: string; label: string }[];
  onChange ?: (value: string) => void;
}

const Selection: React.FC<SelectionProps> = ({ label, options, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    onChange && onChange(value);
  };

  return (
    <div className="custom-select py-2 flex flex-col gap-1">
      <label className="select-label text-gray-500">{label}</label>
      <select className="select rounded-md border border-gray-400 p-2" onChange={handleChange}>
      <option value="" selected disabled>{label}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Selection;
