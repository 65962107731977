import React, { useState } from 'react';

interface TimePickerProps {
  label: string
  onChange: (time: string) => void
}

const TimePicker: React.FC<TimePickerProps> = ({ label, onChange }) => {
  const [time, setTime] = useState(''); // add a state to store the time value

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const time = event.target.value;
    setTime(time);
    onChange(time); // call the onChange callback with the new time value
  };

  return (
    <div className="flex flex-col gap-1 w-full py-2">
      <label htmlFor={label}>{label}</label>
      <input
        type="time"
        name="time"
        id="time"
        className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-[#038aae]"
        value={time}
        onChange={handleChange}
      />
    </div>
  );
};

export default TimePicker;