import axios, { AxiosRequestConfig } from "axios";
import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { handleApiError } from "../api/api";

interface Country {
  countryID: number;
  name: string;
}

interface Region {
  countryID: number;
  name: string;
}

interface City {
  cityID: number;
  cityName: string;
}

interface Facility {
  residentID: number;
  residentName: string;
}

export const useLogin = () => {
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  //
  const [first_name, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  //
  const [middle_name, setMiddleName] = useState("");
  const [middleNameError, setMiddleNameError] = useState("");
  //
  const [last_name, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  //
  const [gender, setGender] = useState("");
  const [genderError, setGenderError] = useState("");
  //
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  //
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  //
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");
  //
  const [region, setRegion] = useState("");
  const [regionError, setRegionError] = useState("");
  //
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  //
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  //
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  //
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  //
  const [countries, setCountries] = useState<Country[]>([]);
  const [selectedCountryId, setSelectedCountryId] = useState<number | null>(
    null
  );
  const [selectedRegionId, setSelectedRegionId] = useState<number | null>(null);
  const [regions, setRegions] = useState<Region[]>([]);
  const [selectedCityId, setSelectedCityId] = useState<number | null>(null);
  const [cities, setCities] = useState<City[]>([]);
  const [selectedFacilityId, setSelectedFacilityId] = useState<number | null>(
    null
  );
  const [facilities, setFacilities] = useState<Facility[]>([]);
  //
  let PageCount: number | undefined = undefined;
  //
  const [errorMessage, setErrorMessage] = useState("");

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];
  const countryOptions = [
    { value: "canada", label: "Canada (+1)" },
    { value: "usa", label: "USA (+1)" },
  ];
  //
  const [password_confirmation, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  //
  const [rememberMe, setRememberMe] = useState(false);

  const [isFirstNameFocused, setIsFirstNameFocused] = useState(false);
  const [isMiddleNameFocused, setIsMiddleNameFocused] = useState(false);
  const [isLastNameFocused, setIsLastNameFocused] = useState(false);
  const [isCityFocused, setIsCityFocused] = useState(false);
  const [isPhoneNumberFocused, setIsPhoneNumberFocused] = useState(false);
  const [isRegionFocused, setIsRegionFocused] = useState(false);
  const [isGenderFocused, setIsGenderFocused] = useState(false);
  const [isCountryFocused, setIsCountryFocused] = useState(false);
  const [isAddressFocused, setIsAddressFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] =
    useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState("canada");

  // const { login } = useAuth({
  //   middleware: "guest",
  //   redirectIfAuthenticated: "/user/dashboard",
  // });

  const handleFirstNameBlur = () => {
    if (!first_name) {
      setFirstNameError("First name field is required");
    } else {
      setFirstNameError("");
    }
    setIsFirstNameFocused(false);
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^[a-zA-Z]*$/.test(value)) {
      // Only allow alphabets
      setFirstName(value);
      if (!value) {
        setFirstNameError("First name field is required");
      } else {
        setFirstNameError("");
      }
    } else {
      setFirstNameError(
        "First name cannot contain numbers or special characters"
      );
    }
  };

  const handleMiddleNameBlur = () => {
    if (!middle_name) {
      setMiddleNameError("Middle name field is required");
    } else {
      setMiddleNameError("");
    }
    setIsMiddleNameFocused(false);
  };

  const handleMiddleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^[a-zA-Z]*$/.test(value)) {
      // Only allow alphabets
      setMiddleName(value);
      if (!value) {
        setMiddleNameError("Middle name field is required");
      } else {
        setMiddleNameError("");
      }
    } else {
      setMiddleNameError(
        "Middle name cannot contain numbers or special characters"
      );
    }
  };

  const handleLastNameBlur = () => {
    if (!last_name) {
      setLastNameError("Last name field is required");
    } else {
      setLastNameError("");
    }
    setIsLastNameFocused(false);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^[a-zA-Z]*$/.test(value)) {
      // Only allow alphabets
      setLastName(value);
      if (!value) {
        setLastNameError("Last name field is required");
      } else {
        setLastNameError("");
      }
    } else {
      setLastNameError(
        "Last name cannot contain numbers or special characters"
      );
    }
  };

  const handleGenderBlur = () => {
    if (!gender) {
      setGenderError("Gender field is required");
    } else {
      setGenderError("");
    }
    setIsGenderFocused(false);
  };

  const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGender(e.target.value);
    if (!gender) {
      setGenderError("Gender field is required");
    } else {
      setGenderError("");
    }
  };

  const handleCountryCodeChange = (value: string) => {
    setSelectedCountry(value);
    setPhoneNumber("");
    setPhoneNumberError("");
  };

  const handlePhoneNumberBlur = () => {
    if (!phoneNumber) {
      setPhoneNumberError("Phone number is required");
    } else if (
      !/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i.test(
        phoneNumber
      )
    ) {
      setPhoneNumberError("Invalid phone number format");
    } else {
      setPhoneNumberError("");
    }

    setIsPhoneNumberFocused(false);
  };

  const handlePhoneNumber = () => {
    if (!phoneNumber) {
      setPhoneNumberError("Phone number is required");
    } else if (
      !/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i.test(
        phoneNumber
      )
    ) {
      setPhoneNumberError("Invalid phone number format");
    } else {
      setPhoneNumberError("");
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^[0-9]*$/.test(value)) {
      setPhoneNumber(value);
      validatePhoneNumber(value);
    } else {
      setPhoneNumberError("Phone number cannot contain letters or special characters");
    }
  };
  

  const validatePhoneNumber = (phoneNumber: string) => {
    let isValid = false;
  
    if (selectedCountry === "canada") {
      // Validate Canadian phone number structure
      isValid = /^(?:\+1)?(?:\d{10})$/.test(phoneNumber);
      if (!isValid) {
        setPhoneNumberError("Invalid Canadian phone number format. Expected format: 10 digits.");
      } else {
        setPhoneNumberError("");
      }
    } else if (selectedCountry === "usa") {
      // Validate USA phone number structure
      isValid = /^(?:\+1)?(?:\d{10})$/.test(phoneNumber);
      if (!isValid) {
        setPhoneNumberError("Invalid USA phone number format. Expected format: 10 digits.");
      } else {
        setPhoneNumberError("");
      }
    }
  };

  const handlePostalCodeBlur = () => {
    if (!postalCode) {
      setPostalCodeError("Postal Code Field is required");
    } else {
      setPostalCodeError("");
    }
    setIsLastNameFocused(false);
  };

  const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostalCode(e.target.value);
    if (!postalCode) {
      setPostalCodeError("Postal Code Field is required");
    } else {
      setPostalCodeError("");
    }
  };

  const handleRegionBlur = () => {
    if (!city) {
      setRegionError("City field is required");
    } else {
      setRegionError("");
    }

    setIsRegionFocused(false);
  };

  const handleRegionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegion(e.target.value);
    if (!city) {
      setRegionError("City field is required");
    } else {
      setRegionError("");
    }
  };

  const handleCityBlur = () => {
    if (!city) {
      setCityError("City field is required");
    } else {
      setCityError("");
    }

    setIsCityFocused(false);
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCity(e.target.value);
    if (!city) {
      setCityError("City field is required");
    } else {
      setCityError("");
    }
  };

  const handleCountryBlur = () => {
    if (!country) {
      setCountryError("State field is required");
    } else {
      setCountryError("");
    }

    setIsCountryFocused(false);
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCountry(e.target.value);
    if (!country) {
      setCountryError("State field is required");
    } else {
      setCountryError("");
    }
  };

  const handleAddressBlur = () => {
    if (!address) {
      setAddressError("Address field is required");
    } else {
      setAddressError("");
    }

    setIsAddressFocused(false);
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
    if (!address) {
      setAddressError("Address field is required");
    } else {
      setAddressError("");
    }
  };

  const handleEmailBlur = () => {
    if (!email) {
      setEmailError("Email is required");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }

    setIsEmailFocused(false);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (!newEmail) {
      setEmailError("Email is required");
    } else if (!/\S+@\S+\.\S+/.test(newEmail)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordBlur = () => {
    if (!password) {
      setPasswordError("Password is required");
    } else if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters");
    } else if (!/[A-Z]/.test(password)) {
      setPasswordError("Password must contain at least one uppercase letter");
    } else if (!/\d/.test(password)) {
      setPasswordError("Password must contain at least one number");
    } else if (!/[@$!%*#?&]/.test(password)) {
      setPasswordError(
        "Password must contain at least one symbol (@ $ ! % * # ? &)"
      );
    } else {
      setPasswordError("");
    }

    setIsPasswordFocused(false);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (!newPassword) {
      setPasswordError("Password is required");
    } else if (newPassword.length < 8) {
      setPasswordError("Password must be at least 8 characters");
    } else if (!/[A-Z]/.test(newPassword)) {
      setPasswordError("Password must contain at least one uppercase letter");
    } else if (!/\d/.test(newPassword)) {
      setPasswordError("Password must contain at least one number");
    } else if (!/[@$!%*#?&]/.test(newPassword)) {
      setPasswordError(
        "Password must contain at least one symbol (@ $ ! % * # ? &)"
      );
    } else {
      setPasswordError("");
    }
  };

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordBlur = () => {
    if (!password_confirmation) {
      setConfirmPasswordError("Confirm Password required");
    } else if (password_confirmation !== password) {
      setConfirmPasswordError("Confirm Passwords do not match");
    } else if (password_confirmation.length < 8) {
      setConfirmPasswordError("Confirm Password must be at least 8 characters");
    } else {
      setConfirmPasswordError("");
    }

    setIsConfirmPasswordFocused(false);
  };

  const handleConfirmPassword = () => {
    if (password_confirmation !== password) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  useEffect(() => {
    handleConfirmPassword();
  }, [password_confirmation]);

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);

    handleConfirmPassword();
  };

  const handleShowConfirmPasswordClick = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      setLoading(true);
      const token = sessionStorage.getItem("token");
      const pageCount = 1;
      const apiUrl = `${process.env.REACT_APP_API_URL}AddCompanion`;

      const config: AxiosRequestConfig = {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
          PageCount: pageCount,
        },
      };

      const response = await axios.post(
        apiUrl,
        {
          firstName: first_name,
          lastName: last_name,
          middleName: middle_name,
          gender,
          emailAddress: email,
          address,
          region,
          cityID: selectedCityId,
          countryID: selectedCountryId,
          phoneNumber,
          postalCode,
          password,
          password_confirmation,
          preferredName: "",
          dateOfBirth: "",
          preferredLocation: "",
          dynamicLocation: "",
          imageUrl: "",
          videoUrl: "",
          introText: "",
          minRate: 0,
          verificationStatus: false,
          averageRating: 0,
          isOrientationDone: false,
          preference: "",
        },
        config
      );

      sessionStorage.setItem("token", response.data.data.token);
      sessionStorage.setItem("companionId", response.data.data.companionId);
      sessionStorage.setItem("firstName", response.data.data.firstName);
      sessionStorage.setItem("lastName", response.data.data.lastName);
      sessionStorage.setItem("email", response.data.data.username);
      sessionStorage.setItem("role", response.data.data.role);

      login(response.data.data);

      setLoading(false);
      setShowModal(true);
      window.scrollTo(0, 0);
    } catch (error: any) {
      handleApiError(error);
      // Handle error
      console.error("Error adding companion:", error);
      // alert(error.response.data.responseMessage);
      setErrorMessage(error.response.data.responseMessage);
      // alert()
      // setErrorMessage(error.response.data.message)
      setLoading(false);

      // You can display an error message to the user or perform other actions here
    }
  };

  useEffect(() => {
    //     adminLogin(setEmail, setPassword, setRememberMe);
  }, []);

  return {
    last_name,
    setLastName,
    middle_name,
    setMiddleName,
    first_name,
    setFirstName,
    city,
    setCity,
    cityError,
    setCityError,
    country,
    setCountry,
    countryError,
    setCountryError,
    region,
    setRegion,
    regionError,
    setRegionError,
    lastNameError,
    middleNameError,
    firstNameError,
    setLastNameError,
    setMiddleNameError,
    setFirstNameError,
    genderError,
    phoneNumberError,
    postalCodeError,
    addressError,
    email,
    setEmail,
    emailError,
    password,
    setPassword,
    password_confirmation,

    setConfirmPassword,
    passwordError,
    setPasswordError,
    confirmPasswordError,
    setConfirmPasswordError,
    showPassword,
    setShowPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    rememberMe,
    setRememberMe,
    //
    countries,
    setCountries,
    cities,
    setCities,
    facilities,
    setFacilities,
    selectedCityId,
    setSelectedCityId,
    selectedFacilityId,
    setSelectedFacilityId,
    selectedCountryId,
    setSelectedCountryId,
    regions,
    setRegions,
    selectedRegionId,
    setSelectedRegionId,
    //
    loading,
    showModal,
    setShowModal,
    //
    genderOptions,
    countryOptions,
    //
    errorMessage,
    //
    gender,
    setGender,
    phoneNumber,
    postalCode,
    address,
    //
    isFirstNameFocused,
    setIsFirstNameFocused,
    isLastNameFocused,
    setIsLastNameFocused,
    isCityFocused,
    setIsCityFocused,
    isCountryFocused,
    setIsCountryFocused,
    isAddressFocused,
    setIsAddressFocused,
    isPasswordFocused,
    setIsPasswordFocused,
    isConfirmPasswordFocused,
    setIsConfirmPasswordFocused,
    isEmailFocused,
    setIsEmailFocused,
    isPhoneNumberFocused,
    isRegionFocused,
    isGenderFocused,
    //
    handleCityChange,
    handleCountryBlur,
    handleCityBlur,
    handleCountryChange,
    handleAddressBlur,
    handleAddressChange,
    handleLastNameBlur,
    handleMiddleNameBlur,
    handleFirstNameBlur,
    handleEmailBlur,
    handlePasswordBlur,
    handleSubmit,
    handleLastNameChange,
    handleMiddleNameChange,
    handleCountryCodeChange,
    handlePhoneNumberChange,
    handlePhoneNumberBlur,
    handlePostalCodeChange,
    handlePostalCodeBlur,
    handleFirstNameChange,
    handleEmailChange,
    handlePasswordChange,
    handleShowPasswordClick,
    handleConfirmPasswordBlur,
    handleConfirmPasswordChange,
    handleShowConfirmPasswordClick,
  };
};
