import React, { useEffect, useState } from "react";
import Selection from "../components/Selection";
import { useLogin } from "../hooks/useLogin";
import { getCities } from "../api/general/citiesApi";
// import getFacilitiesByCityId from "../api/general/facilitiesApi";

import { Navigate, useNavigate } from "react-router-dom";
import { handleApiError } from "../api/api";
import { getFacilitiesByCityId } from "../api/general/facilitiesApi";

interface City {
  cityID: number;
  cityName: string;
}

const FindCompanion = () => {
  const navigate = useNavigate()
  const {
    cities,
    setCities,
    facilities,
    setFacilities,
    selectedFacilityId,
    setSelectedFacilityId,
    selectedCityId,
    setSelectedCityId,
  } = useLogin();

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const citiesData = await getCities();
        setCities(citiesData);
      } catch (error) {
    handleApiError(error);
        console.error("Error fetching regions:", error);
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    const fetchFacilities = async () => {
      if (selectedCityId) {
        try {
          const facilitiesData = await getFacilitiesByCityId(selectedCityId);
          setFacilities(facilitiesData);
        } catch (error) {
    handleApiError(error);
          console.error("Error fetching facilites:", error);
        }
      }
    };

    fetchFacilities();
  }, [selectedCityId]);

  const handleCityChange = (value: string) => {
    const cityId = parseInt(value);
    setSelectedCityId(cityId);
  };

  const handleFacilityChange = (value: string) => {
    const facilityId = parseInt(value);
    console.log(facilityId )
    setSelectedFacilityId(facilityId);
  };

  const handleSearch = () => {
    // Redirect to the search results page with selected city and facility IDs as query parameters
    // navigate(`/find-companions?ct=${selectedCityId}&rs=${selectedFacilityId}`);
    navigate(`/find-companions?ct=${selectedCityId}&rs=1`);
  };
  return (
    <div className="mt-[88px] w-[400] sm:w-[500px] mx-auto py-6 px-3 sm:px-0">
      <div className="py-4 text-center">
        <h2 className="py-2 text-3xl font-bold">Self Serve</h2>
        <p className="py-3 max-w-[500px] mx-auto">
          Self Serve to book a companion yourself is coming soon, however, we
          can help you book a companion by filling out the{" "}
          <a href="http://tinyurl.com/linkrxintake" className="text-[#038aae]">
            Intake Form
          </a>
        </p>
      </div>
      <h2 className="text-green text-2xl py-3 text-center">Find companion</h2>
      <div className="py-3">
        <Selection
          label="City"
          options={cities.map((city) => ({
            value: city.cityID.toString(),
            label: city.cityName,
          }))}
          onChange={handleCityChange}
        />
        {/* <Selection
          label="Facility"
          options={facilities.map((facility) => ({
            value: facility.residentID.toString(),
            label: facility.residentName,
          }))}
          onChange={handleFacilityChange}
        /> */}
      </div>
      <button
        onClick={handleSearch}
        disabled={!selectedCityId}
        className={`py-2 px-4 text-white ${
          selectedCityId ? "bg-[#038aae]" : "bg-gray-400 cursor-not-allowed"
        } rounded-lg`}
      >
        Search
      </button>
    </div>
  );
};

export default FindCompanion;
