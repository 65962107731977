import React, { useEffect, useState } from "react";
import Selection from "./Selection";
import MultiSelection from "./MultiSelection";
// import getFacilitiesByCityId from "../api/general/facilitiesApi";
import { getCities } from "../api/general/citiesApi";
import { useLogin } from "../hooks/useLogin";
import { getLanguage } from "../api/general/stepApi";
import { handleApiError } from "../api/api";
import {
  getFacilities,
  getFacilitiesByCityId,
} from "../api/general/facilitiesApi";

interface FilterProps {
  setSelectedLanguageID: (languageID: string) => void;
  setSelectedFacilityID: (facilityID: string) => void; // New prop for Facility ID
}

interface LanguageSelection {
  languageID: string;
  name: string;
}

const Filter: React.FC<FilterProps> = ({ setSelectedLanguageID, setSelectedFacilityID, }) => {
  const [selectedAvailableDays, setSelectedAvailableDays] = useState<string[]>(
    []
  );
  const [selectedLanguage, setSelectedLanguage] = useState("");
  // const [selectedLanguageID, setSelectedLanguageID] = useState("");
  const [languages, setLanguages] = useState<LanguageSelection[]>([]);
  const {
    cities,
    setCities,
    facilities,
    setFacilities,
    selectedFacilityId,
    setSelectedFacilityId,
    selectedCityId,
    setSelectedCityId,
    genderOptions,
    setGender,
  } = useLogin();

  // useEffect(() => {
  //   const fetchCities = async () => {
  //     try {
  //       const citiesData = await getCities();
  //       setCities(citiesData);
  //     } catch (error) {
  //       handleApiError(error);
  //       console.error("Error fetching regions:", error);
  //     }
  //   };

  //   fetchCities();
  // }, []);

  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        const facilitiesData = await getFacilities();
        // console.log({ facilitiesData });

        setFacilities(facilitiesData);
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching facilites:", error);
      }
    };

    fetchFacilities();
  }, [selectedCityId]);

  const handleCityChange = (value: string) => {
    const cityId = parseInt(value);
    setSelectedCityId(cityId);
  };

  // const handleFacilityChange = (value: string) => {
  //   const facilityId = parseInt(value);
  //   console.log(facilityId);
  //   setSelectedFacilityId(facilityId);
  // };
  const days = [
    { value: "1", label: "Sunday" },
    { value: "2", label: "Monday" },
    { value: "3", label: "Tuesday" },
    { value: "4", label: "Wednesday" },
    { value: "5", label: "Thursday" },
    { value: "6", label: "Friday" },
    { value: "7", label: "Saturday" },
  ];

  const handleAvailableDaysChange = (selectedDays: any) => {
    // If selectedDays is a string, convert it to an array containing that string
    const updatedAvailableDays =
      typeof selectedDays === "string" ? [selectedDays] : selectedDays;

    // Map the selected days to their corresponding values
    const selectedDayValues = updatedAvailableDays
      .map((day: any) => {
        const selectedDay = days.find((d) => d.label === day);
        return selectedDay ? selectedDay.value : null;
      })
      .filter(Boolean); // Filter out any null values

    // Update the state by appending the selected day values to the existing array
    setSelectedAvailableDays((prevSelectedDays) => [
      ...prevSelectedDays,
      ...selectedDayValues,
    ]);
  };

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const languageData = await getLanguage();
        setLanguages(languageData);
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching regions:", error);
      }
    };

    fetchLanguages();
  }, []);

  const formattedLanguage = languages.map((item) => ({
    value: item.languageID.toString(),
    label: item.name,
  }));

  const handleLanguageChange = (value: string) => {
    // Update the selected language ID in the parent component
    setSelectedLanguageID(value);
  };

  const handleFacilityChange = (value: string) => {
    setSelectedFacilityID(value); // Pass selected Facility ID back to parent
  };

  // const handleLanguageChange = (value: string) => {
  //   // Find the selected experience object from the options array
  //   const selectedOption = languages.find((value) => value.languageID);

  //   // If selectedOption is found, update the state with the selected experience
  //   if (selectedOption) {
  //     setSelectedLanguage(selectedOption.name);
  //     setSelectedLanguageID(selectedOption.languageID.toString()); // Convert to string if needed
  //   } else {
  //     setSelectedLanguage("");
  //     setSelectedLanguageID("");
  //   }
  // };

  const hanldleGenderChange = (value: string) => {
    setGender(value);
  };
  return (
    <div className="px-4 py-6 bg-white my-4 rounded-lg shadow-xl">
      {/* <h2 className="py-2 text-center text-2xl">Filter</h2>
      <p className="py-1 text-sm text-center w-[300px] mx-auto">
        Search for companions using more detailed criteria
      </p> */}
      <div className="py-3 gap-2">
        <div className="flex items-center justify-center gap-4">
          <Selection
            label="Facility"
            options={facilities.map((facility) => ({
              value: facility.residentID.toString(),
              label: facility.residentName,
            }))}
            onChange={handleFacilityChange}
          />
          <Selection
            label="Languages"
            options={formattedLanguage}
            onChange={handleLanguageChange}
          />
        </div>
        {/* <Selection
          label="City"
          options={cities.map((city) => ({
            value: city.cityID.toString(),
            label: city.cityName,
          }))}
          onChange={handleCityChange}
        /> */}

        {/* <MultiSelection label={"Companion Availability"} options={days} /> */}

        {/* <div className="my-2">
          <label htmlFor="">
            Additional Information (Your expectation from companion)
          </label>
          <textarea
            name=""
            id=""
            className="w-full border border-gray-400 rounded-md"
          ></textarea>
        </div>
        <Selection label="Gender" options={genderOptions} onChange={hanldleGenderChange} /> */}
        {/* <button className="w-full bg-gradient-to-br from-[#038aae] to-[#038aae90] text-white py-2 rounded-lg">
          Filter
        </button> */}
      </div>
    </div>
  );
};

export default Filter;
