import React from "react";
import { Link } from "react-router-dom";
const instagram = require("../../assets/icons/mdi_instagram.png");
const linkedin = require("../../assets/icons/mdi_linkedin.png");
const facebook = require("../../assets/icons/ic_baseline-facebook.png");
const twitter = require("../../assets/icons/mdi_twitter.png");

const Logo = require("../../images/logo.png");

const Footer = () => {
  return (
    <div className="bg-[#038aae30] py-8">
      <div className="px-8 md:px-0 flex items-start md:items-center justify-between flex-col md:flex-row gap-4 md:max-w-7xl mx-auto pb-8">
        <div className="flex gap-2 flex-col py-4 md:py-0">
          <img src={Logo} alt="" className="h-24 w-[300px]" />
          <p className="w-[20em] my-4">
            Feel the diference. Find trustworthy companions committed to caring
            and engaging with seniors.
          </p>
          <div className="flex gap-4">
            <Link to="/">
              <img src={facebook} alt="" />
            </Link>
            <Link to="/">
              <img src={instagram} alt="" />
            </Link>
            <Link to="/">
              <img src={linkedin} alt="" />
            </Link>
            <Link to="/">
              <img src={twitter} alt="" />
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-16 pt-8 md:pt-0 md:px-12">
          <div className="flex flex-col gap-2">
            <h2 className="font-bold text-xl">Quick Links</h2>
            <Link to="/about-us" className="text-sm py-1">
              About Us
            </Link>
            <Link to="/about-us" className="text-sm py-1">
              Our Services
            </Link>
            <Link to="/about-us" className="text-sm py-1">
              Terms of Use
            </Link>
            <Link to="/privacy-policy" className="text-sm py-1">
              Privacy Policy
            </Link>
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="font-bold text-xl">Contact Us</h2>
            <p className="text-sm">6758 188 Street Surrey V4N6K2</p>
            <Link to="tel:+1 (778) 318-4402" className="text-sm">
              +1 (778) 318-4402
            </Link>
            <Link to="mailto:guaranteedtrustcare@linkrx.ca" className="text-sm">
              Send us a mail
            </Link>
          </div>
        </div>
        
      </div>
      <h2 className="text-center pt-12">
          LinkRx Inc © 2024 All Rights Reserved
        </h2>
    </div>
  );
};

export default Footer;
