import axios from 'axios';
import { handleApiError } from '../api';

// interface EduQualification {
//     educationalQualificationItemID: string,
//     educationalQualificationItemName: string
//   }

export const getEducationQualificationItem = async () => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}GetEducationalQualificationItem`;
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw new Error('Failed to fetch regions');
  }
};


export const getExperienceItem = async () => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}GetExperienceItem`;
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw new Error('Failed to fetch regions');
  }
};

export const getScreeningItem = async () => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}GetScreeningItems`;
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw new Error('Failed to fetch regions');
  }
};

export const getLanguage = async () => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}GetLanguages`;
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw new Error('Failed to fetch regions');
  }
};
