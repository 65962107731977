import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

interface ModalProps {
  closeModal: () => void;
  children: ReactNode;
  link: string;
}

const Modal: React.FC<ModalProps> = ({ children, closeModal, link }) => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleProceed = () => {
    if (link.startsWith("http://") || link.startsWith("https://")) {
      window.location.href = link;
    } else {
      navigate(link);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-[400px] z-[9999] bg-white text-black rounded-lg shadow-lg p-6">
        <span
          className="absolute top-0 right-0 m-4 cursor-pointer text-green-800"
          onClick={closeModal}
        >
          &times;
        </span>
        <div className="text-center">
          <div className="flex items-center justify-center my-2">
            <div className="bg-green-500 text-white rounded-full h-14 w-14 flex items-center justify-center">
              <FontAwesomeIcon icon={faCheck} className="h-8 w-8" />
            </div>
          </div>

          <h2 className="text-xl font-semibold mb-4">Success</h2>
          <p className="text-lg mb-4">{children}</p>
          <button className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded" onClick={handleProceed}>
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
