// api.ts
import axios from 'axios';
import { toast } from 'react-toastify';

export const handleApiError = (error: any) => {
  let errorMessage = `Error: ${error.message}`;

  if (error.response) {
    const { data } = error.response;
    errorMessage = `Error: ${data.message || error.message || error.responseMessage}`;
    
    if (data.userStatus) {
      errorMessage += ` - ${data.userStatus}`;
    }
  }

  toast.error(errorMessage);
};


export const fetchServiceCategoryItems = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}GetServiceCategoryItem`
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const addCompanionService = async (
  companionID: string,
  companionServiceString: string,
  token: string,
  pageCount: number
) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}AddCompanionService`;
    const response = await axios.post(
      apiUrl,
      {
        companionID,
        companionServiceName: companionServiceString,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          PageCount: pageCount,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const AddCompanionAvailability = async (
  payload: any,
  token: string,
  pageCount: number,
) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}AddCompanionAvailabilityOnPortal`;
    const response = await axios.post(
      apiUrl,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          PageCount: pageCount,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const AddCompanionExperience = async (
  payload: any,
  token: string,
  // pageCount: number
) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}AddCompanionExperience`;
    const response = await axios.post(
      apiUrl,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          // PageCount: pageCount,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const AddLanguage = async (
  payload: any,
  token: string,
  // pageCount: number
) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}AddCompanionLanguage`;
    const response = await axios.post(
      apiUrl,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          // PageCount: pageCount,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const AddEducation = async (
  payload: any,
  token: string,
  pageCount: number
) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}AddEducationalQualification`;
    const response = await axios.post(
      apiUrl,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          PageCount: pageCount,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const AddCredibilityWithUpload = async (
  payload: any,
  token: string,
  pageCount: number
) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}AddCredibilityWithUpload`;
    const response = await axios.post(
      apiUrl,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          PageCount: pageCount,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};