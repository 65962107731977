import React, { useState } from "react";
import CustomDatePicker from "../utils/DatePicker";
import MultiSelection from "./MultiSelection";
import Selection from "./Selection";
import { AddCompanionAvailability, handleApiError } from "../api/api";
import TimePicker from "../utils/TimePicker";
import Modal from "./SuccessModal";
import StepTwoSuccessModal from "./StepTwoSuccessModal";

interface Step2Props {
  onPrev: () => void;
  onNext: () => void;
}

const Step2: React.FC<Step2Props> = ({ onPrev, onNext }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedAvailableDays, setSelectedAvailableDays] = useState<string[]>(
    []
  );
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const [selectedStartTime, setSelectedStartTime] = useState("");
  const [selectedEndTime, setSelectedEndTime] = useState("");
  const [flexibleDay, setFlexibleDay] = useState<Boolean | null>(null);
  const [flexiblePeriod, setFlexiblePeriod] = useState<Boolean | null>(null);
  const [error, setError] = useState<any>(null);
  const [selectedTime, setSelectedTime] = useState("");
  const [loading, setLoading] = useState(false);

  const handleTimeChange = (time: string) => {
    setSelectedTime(time);
  };

  const isNextFormValid = () => {
    const dateSelected =
      flexibleDay === true || (selectedStartDate && selectedEndDate);
    const timeSelected =
      flexiblePeriod === true || (selectedStartTime && selectedEndTime);
    const flexibilitySelected = flexiblePeriod !== null && flexibleDay !== null;

    return flexibilitySelected && dateSelected && timeSelected;
  };

  const FlexibilityOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  const days = [
    { value: "1", label: "Sunday" },
    { value: "2", label: "Monday" },
    { value: "3", label: "Tuesday" },
    { value: "4", label: "Wednesday" },
    { value: "5", label: "Thursday" },
    { value: "6", label: "Friday" },
    { value: "7", label: "Saturday" },
  ];

  const Times = Array.from({ length: 24 }, (_, i) => ({
    value: i.toString(),
    label: `${i === 0 ? "12" : i > 12 ? i - 12 : i}:00 ${i < 12 ? "AM" : "PM"}`,
  }));

  const handleAvailableDaysChange = (selectedDays: any) => {
    const updatedAvailableDays =
      typeof selectedDays === "string" ? [selectedDays] : selectedDays;

    const selectedDayValues = updatedAvailableDays
      .map((day: any) => {
        const selectedDay = days.find((d) => d.label === day);
        return selectedDay ? selectedDay.value : null;
      })
      .filter(Boolean);

    setSelectedAvailableDays((prevSelectedDays) => [
      ...prevSelectedDays,
      ...selectedDayValues,
    ]);
  };

  const handleStartDateChange = (date: Date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date: Date) => {
    setSelectedEndDate(date);
  };

  const handleStartTimeChange = (time: any) => {
    console.log(time);
    setSelectedStartTime(time);
  };

  const handleEndTimeChange = (time: any) => {
    setSelectedEndTime(time);
  };

  const handleFlexibleDays = (value: any) => {
    setFlexibleDay(value === "yes");
  };

  const handleFlexiblePeriod = (value: any) => {
    setFlexiblePeriod(value === "yes");
  };

  const formattedStartDate = `${(selectedStartDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${selectedStartDate
    .getDate()
    .toString()
    .padStart(2, "0")}/${selectedStartDate.getFullYear()}`;

  const formattedEndDate = `${selectedEndDate.getFullYear()}-${(
    selectedEndDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${selectedEndDate
    .getDate()
    .toString()
    .padStart(2, "0")}`;

  const getDayOfWeek = (date: Date) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek[date.getDay()];
  };

  const handleError = (error: any) => {
    setError(error);
  };

  const handleNextClick = async () => {
    const companionID = sessionStorage.getItem("companionId");
    if (!companionID) {
      console.error("Companion ID is not available.");
      return;
    }

    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("Token is not available.");
      return;
    }

    const pageCount = 3;

    const availableDay = getDayOfWeek(selectedStartDate);
    console.log({ availableDay });

    const availableDaysString = selectedAvailableDays.join(",");

    const payload = {
      availabilityStartDate: formattedStartDate,
      availabilityStartTime: selectedStartTime,
      availabilityEndTime: selectedEndTime,
      flexibleStartDate: flexibleDay,
      availabilitySpecific: flexiblePeriod,
      availableDays: availableDay,
      scheduledDateStatus: true,
      scheduledDate: new Date().toISOString().slice(0, 10),
      companionID: sessionStorage.getItem("companionId"),
    };

    try {
      setLoading(true);
      await AddCompanionAvailability(payload, token, pageCount);
      setLoading(false);
      setShowModal(true);
    } catch (error) {
      handleApiError(error);
      setLoading(false);
      handleError(error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  // Set minimum date to today
  const minDate = new Date();

  return (
    <div className="">
      {showModal && (
        <StepTwoSuccessModal closeModal={closeModal} onNext={onNext}>
          <p>
            Your availability details have been added successfully, would you
            like to add availability or proceed to the next step?
          </p>
        </StepTwoSuccessModal>
      )}
      <div className="text-center">
        <h1 className="text-3xl py-4">Availability Schedule</h1>
        <p className="text-md text-gray-600 max-w-[400px] sm:max-w-[500px] py-2">
          (Any selected time is temporary, you still have to select time for
          each day in your portal after you have been accepted by the
          organization)
        </p>
      </div>
      <div className="flex gap-4 py-3">
        <CustomDatePicker
          label="Schedule Date (Please Select Date)"
          selectedDate={selectedStartDate}
          onSelectDate={handleStartDateChange}
          minDate={minDate}  // Set minDate here
        />
      </div>
      <p className="text-lg py-4 text-center text-gray-500">
        What time can you work?
      </p>
      <div>
        <div className="flex items-center justify-center gap-2">
          <TimePicker label="Start Time" onChange={handleStartTimeChange} />
          <TimePicker label="End Time" onChange={handleEndTimeChange} />
        </div>
      </div>
      <p className="text-lg py-4 text-center text-gray-500">
        How flexible can you be?
      </p>
      <div className="py-1">
        <Selection
          label="Is your Start Date Flexibile"
          options={FlexibilityOptions}
          onChange={handleFlexibleDays}
        />
      </div>
      <div className="py-1">
        <Selection
          label="Would you only be reachable in your available period?"
          options={FlexibilityOptions}
          onChange={handleFlexiblePeriod}
        />
      </div>
      <button
        onClick={handleNextClick}
        disabled={!isNextFormValid() || loading}
        className={`rounded-lg ${
          !isNextFormValid() || loading
            ? "bg-gray-300 text-gray-500 cursor-not-allowed"
            : "bg-[#038aae]"
        } text-white text-lg p-2`}
      >
        {loading ? "Submitting..." : "Proceed"}
      </button>
    </div>
  );
};

export default Step2;