import React from 'react';
const Cabhi = require("../images/cabhi.jpeg");
const Coastcapital = require("../images/coastcapital.jpeg");
const Louis = require("../images/louisB.jpeg");
const Langley = require("../images/langley.jpeg");


const SponsorsPartners: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="text-center">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">Our Sponsors & Partners</h2>
        <p className="mt-4 text-lg leading-6 text-gray-500">We are proud to be supported by these organizations</p>
      </div>
      <div className="mt-10 flex flex-col md:flex-row justify-between">
        <div className="md:w-1/2 px-4 mb-8 md:mb-0">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">Sponsors</h3>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="bg-gray-100 p-6 shadow-md rounded-lg">
              <img src={Cabhi} alt="Sponsor 1" className="mx-auto h-16" />
            </div>
            <div className="bg-gray-100 p-6 shadow-md rounded-lg">
              <img src={Coastcapital} alt="Sponsor 2" className="mx-auto h-16" />
            </div>
          </div>
        </div>
        <div className="md:w-1/2 px-4">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">Partners</h3>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="bg-gray-100 p-6 shadow-md rounded-lg">
              <img src={Louis} alt="Partner 1" className="mx-auto h-16" />
            </div>
            <div className="bg-gray-100 p-6 shadow-md rounded-lg">
              <img src={Langley} alt="Partner 2" className="mx-auto h-16" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponsorsPartners;