import React, { useEffect, useState } from "react";
import Input from "../components/Input";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { handleApiError } from "../api/api";
import { toast } from "react-toastify";

const VerifyAccount = () => {
  const [code, setCode] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [resending, setResending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resendTime, setResendTime] = useState(0);

  const firstName = sessionStorage.getItem("firstName");
  const lastName = sessionStorage.getItem("lastName");

  const navigate = useNavigate();

  const token = sessionStorage.getItem("token");
  useEffect(() => {
    let timer: any;
    if (!resending && resendTime > 0) {
      timer = setTimeout(() => {
        setResendTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [resending, resendTime]);

  const submitCode = () => {
    if (code === "") {
      alert("Please enter a code");
      return;
    }
    setSubmitting(true);
    const payload = {
      Email: sessionStorage.getItem("email"),
      Code: code,
    };
    const pageCount = 1;
    const apiUrl = `${process.env.REACT_APP_AUTH_API_URL}ValidateCode`;
    axios
      .post(apiUrl, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          // PageCount: pageCount,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data === 1) {
          // Handle successful validation, maybe redirect user
          sessionStorage.setItem("page", `${pageCount}`);
          navigate("/companion-registration");
        } else {
          toast.error(errorMessage);
          setErrorMessage("Invalid code entered");
        }
      })
      .catch((err) => {
        handleApiError(err);
        toast.error(errorMessage);
        setErrorMessage("Unable to verify code");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const resendCodeMtd = () => {
    if (resending) return;
    setResending(true);
    setResendTime(60);
    const payload = {
      Email: sessionStorage.getItem("email"),
    };
    // console.log({ payload });
    const apiUrl = `${process.env.REACT_APP_AUTH_API_URL}ResendCode`;
    axios
      .post(apiUrl, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data === 1) {
          alert("Code has been resent to your email");
        } else {
          toast.error(errorMessage);
          setErrorMessage("Unable to send code");
        }
      })
      .catch((err) => {
        handleApiError(err);
        toast.error(errorMessage);
        setErrorMessage("Unable to send code");
      })
      .finally(() => {
        setResending(false);
      });
  };

  return (
    <div className="flex items-center justify-center mt-[88px] h-[70vh]">
      <div className="bg-white text-black p-6 shadow-lg px-4">
        <div className="text-center">
          <h2 className="text-xl font-semibold mb-4">Verify Email</h2>
          <p className="text-lg mb-4 w-[400px] sm:w-[500px]">
            Hello {firstName} {lastName}, please enter the code you received
            below to activate your account
          </p>
          <div className="text-start">
            <Input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              label="Code"
              placeholder="code"
            />
          </div>
          <h2 className="text-red-500">{errorMessage}</h2>
          <div className="flex items-center justify-center gap-4 my-3">
            <button
              className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
              onClick={resendCodeMtd}
              disabled={resending || resendTime > 0}
            >
              {resending ? "Resending..." : "Resend"}
            </button>
            <button
              className="bg-[#038aae] hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
              onClick={submitCode}
              disabled={submitting}
            >
              {submitting ? "Submitting..." : "Proceed"}
            </button>
          </div>
          <div className="text-center mt-2">
            {resendTime > 0 && (
              <p>
                Resend in <strong>{resendTime}</strong> seconds
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyAccount;
