// ErrorComponent.tsx
import React from "react";

interface ErrorComponentProps {
  error: any;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({ error }) => {
  return <div>Error: {error.message}</div>;
};

export default ErrorComponent;
