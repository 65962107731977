import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const Image1 = require("../../images/img1.jpg");
const Image2 = require("../../images/img2.jpg");
const Image3 = require("../../images/img3.jpg");
const Image4 = require("../../images/img4.jpg");
const Image5 = require("../../images/img5.jpg");

const images: string[] = [Image1, Image2, Image3, Image4, Image5];

const Banner: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === images.length - 1 ? 0 : prevSlide + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="banner bg-gray-200 w-full h-[80vh] relative overflow-hidden mt-[89px]" style={{ fontFamily: "'Raleway', sans-serif" }}>
      {images.map((image, index) => (
        <div
          key={index}
          className={`slide z-[9] absolute top-0 left-0 w-full h-full bg-cover bg-center object-contain transition-opacity duration-1000 ${
            index === currentSlide ? "opacity-100" : "opacity-0"
          }`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="absolute w-full h-full bg-black opacity-40"></div>
          <div className="text-lg gap-4 font-bold text-white inset-0 flex items-center md:items-start flex-col justify-center absolute z-[999] max-w-7xl mx-auto px-4">
            <h2 className="font-bold text-[60px] md:text-[80px] py-1 md:py-4 leading-10 w-[600px] text-center md:text-start">You’re Never</h2>
            <h2 className="font-bold text-[60px] md:text-[80px] py-1 md:py-4 leading-10 w-[600px] text-center md:text-start">Alone.</h2>
            <div className="text-md font-light w-[400px] md:w-[600px] text-center md:text-start p-4 md:p-0">
              LinkRx matches older adults and families at care facilities with
              trustworthy companions who reflect your values and cultural
              preferences to provide psychosocial support and assist with daily
              tasks
            </div>
            <Link to="find-companion" className="w-64 rounded-lg bg-white bg-gradient-to-br from-[#038aae] to-[#038aae90] text-center py-4">Find a companion</Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Banner;
